import { render, staticRenderFns } from "./number-of-guests.vue?vue&type=template&id=fc16200a&"
import script from "./number-of-guests.vue?vue&type=script&lang=ts&"
export * from "./number-of-guests.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports