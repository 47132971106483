
import { Component, Vue } from 'vue-property-decorator';
import RatesExcelBtn from '@/modules/rates/components/rates-excel-btn.vue';

import ProviderFilter from '@/modules/cluster/components/_filters/providers-rate.vue';
import PriceFilter from '@/modules/cluster/components/_filters/price-type.vue';
import LosFilter from '@/modules/cluster/components/_filters/los.vue';
import NumberOfGuestsFilter from '@/modules/cluster/components/_filters/number-of-guests.vue';
import RoomTypeFilter from '@/modules/cluster/components/_filters/room-type.vue';
import MealTypeFilter from '@/modules/cluster/components/_filters/meal-type.vue';
import ChainFilters from '@/modules/chain/components/chain-filters.vue';

@Component({
    components: {
        ProviderFilter,
        PriceFilter,
        RatesExcelBtn,
        LosFilter,
        NumberOfGuestsFilter,
        RoomTypeFilter,
        MealTypeFilter,
        ChainFilters,
    },
})
export default class RatesClusterActions extends Vue {
    get currentPath() {
        return this.$route.path;
    }

    get currentRouteName() {
        return this.$route.name!;
    }

    get isChainLevel() {
        return this.$route.path.split('/')[1] === 'chain';
    }

    get baseBranchRoute() {
        const currentBranch = this.$route.name!.split('.')[0];

        switch (currentBranch) {
            case 'chain':
                return 'chain.rates';

            case 'cluster':
                return 'cluster.rates';

            default: break;
        }

        return 'rates';
    }

    get ratesTableRoute() {
        return `${this.baseBranchRoute}.table`;
    }
}
