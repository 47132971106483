
import { $enum } from 'ts-enum-util';
import { Vue, Component } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type ClusterService from '@/modules/cluster/cluster.service';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import type ProvidersService from '@/modules/providers/providers.service';
import ChainService, { ChainServiceS } from '../chain.service';
import ChainGroup, { groupNames } from '../interfaces/chain-group.enum';

@Component({
    components: {
        CustomSelect,
    },
})
export default class ChainFilters extends Vue {
    @inject(ChainServiceS) private chainService!: ChainService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;
    @inject(KEY.ClusterService) private clusterService!: ClusterService;

    brand: string = '';
    region: string = '';
    country: string = '';

    get isClusterView() {
        return this.$route.name!.includes('.cluster');
    }

    get isLoading() {
        return this.chainService.isDictionaryLoading;
    }

    get needCountryFilter() {
        return this.$route.params.group !== ChainGroup.COUNTRY;
    }

    get needRegionFilter() {
        return true
            && this.$route.params.group !== ChainGroup.COUNTRY
            && this.$route.params.group !== ChainGroup.REGION;
    }

    get groupItems() {
        return $enum(ChainGroup)
            .map(value => ({
                value,
                name: groupNames[value],
            }));
    }

    get settings() {
        return this.chainService.settings;
    }

    get group() {
        return this.settings.groupBy;
    }

    set group(value: ChainGroup) {
        this.settings.groupBy = value;
    }

    get providerItems() {
        const { rateProviders } = this.providersService.userProviders;

        return this.providersService.toItemsList(rateProviders);
    }

    get provider() {
        return this.chainService.settings.provider;
    }

    get brandItems() {
        return this.chainService.getItemSet('brand');
    }

    get regionItems() {
        return this.chainService.getItemSet('region');
    }

    get countryItems() {
        return this.chainService.getItemSet('country');
    }

    get cityItems() {
        return this.chainService.getItemSet('city');
    }

    set provider(value: string) {
        this.chainService.settings.provider = value;
        this.clusterService.saveCurrentProvider(value);
    }
}
